import clsx from "clsx"
import {useEffect} from "react"

import {IconClose} from "../elements/Icon"

type ContactCardProps = {
  title: string
  description?: string
  className?: string
  onClose?: () => void
}

export const ContactCard = ({
  title,
  description,
  className,
  onClose,
}: ContactCardProps) => {
  useEffect(() => {
    const formDiv = document.querySelector(".gorgias-contact-form")
    if (formDiv) {
      const script = document.querySelector(
        'script[src="https://contact.gorgias.help/api/contact-forms/loader.js?v=3"]',
      )
      if (!script) {
        const script = document.createElement("script")
        script.src =
          "https://contact.gorgias.help/api/contact-forms/loader.js?v=3"
        script.setAttribute("data-gorgias-contact-form-uid", "kxsx8t95")
        formDiv.appendChild(script)
        return () => {
          if (formDiv.contains(script)) {
            formDiv.removeChild(script)
          }
        }
      }
    }
  }, [])

  return (
    <div
      className={clsx(
        "flex flex-col gap-2 md:mx-auto max-w-[768px] rounded-xl bg-white p-2 md:p-3 relative",
        className,
      )}
    >
      {!!onClose && (
        <IconClose
          stroke="cosmosgrey"
          onClick={onClose}
          className="cursor-pointer absolute top-2 right-2"
        />
      )}
      <h2 className="text-cosmosgrey text-heading font-bold">{title}</h2>
      {!!description && <p className="text-steel">{description}</p>}
      <div className="gorgias-contact-form"></div>
    </div>
  )
}
